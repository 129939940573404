.machineTypeDiv {
  border-radius: 10px;
  border: 1px solid;
  margin-bottom: 5px;
  padding-bottom: 5px;
}

.machine-config-div {
  margin: 20px 0;
}

.machine-config-div .input-box-outer-div {
  margin: 0px !important;
}

.machine-config-div .form-check {
  padding-left: 0.25rem;
}

.machine-config-div .check-box-input {
  position: relative;
  margin: auto;
  cursor: pointer;
  width: 20px;
  height: 20px;
}

.machine-config-div .table th,
.table td {
  vertical-align: middle !important;
}

.header-txt h2 {
  color: var(--dark-blue);
}

.add-machine-type-btn {
  text-align: center;
  cursor: pointer;
}
