.accordion-tab .accordion {
  width: 100%;
}

.accordion-tab .accordion-btn {
  cursor: pointer;
  padding: 18px;
  width: 100%;
  border: none;
}

.accordion-tab h5 {
  display: inline;
}

.accordion-tab .maintenances-number {
  font-size: 125%;
  float: right;
}

.delayed-maintenance-btn {
  color: #721c24;
  background-color: #f8d7da;
  border-color: #f5c6cb;
}

.on-going-maintenance-btn {
  color: #004085;
  background-color: #cce5ff;
  border-color: #b8daff;
}

.up-coming-maintenance-btn {
  color: #856404;
  background-color: #fff3cd;
  border-color: #ffeeba;
}

.delayed-maintenance-content {
  padding: 18px;
  background-color: #f8d7da;
}

.on-going-maintenance-content {
  padding: 18px;
  background-color: #cce5ff;
}

.up-coming-maintenance-content {
  padding: 18px;
  background-color: #fff3cd;
}
