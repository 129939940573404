.form-outer-div {
  position: relative;
}

.form-outer-div .input-field {
  width: 100%;
}

.form-outer-div .eye-icon-password {
  position: absolute;
  top: 9px;
  right: 13px;
  cursor: pointer;
}

.input-field {
  appearance: none;
  background: 0 0;
  border: none;
  border-radius: 4px;
  color: inherit;
  display: block;
  font-family: inherit;
  font-weight: 400;
  outline: 0;
  padding: 8px 12px;
  resize: none;
  width: 100%;
  display: flex;
  background-color: #fff;
  box-shadow: #8891aa 0 0 0 1px;
  margin: 0;
}

.input-box-top-name {
  margin: 0 0 4px;
  padding: 0;
  font-weight: 600;
  color: #121c2d;
  cursor: pointer;
  text-transform: none;
  letter-spacing: 0;
}
