:root {
  --light-blue: #3579bc;
  --light-blue-1: #d2dfea;
  --dark-blue: #337ab7;
  --mid-blue: #0278bb;
  --dark-blue: #1a2d3b;
  --light-gray: #f0f2f5;
  --light-mid-gray: #bebfc0;
  --light-black: rgba(0, 0, 0, 0.658);
  --color-btn-text: #3f51b5;
  --color-btn-bg: rgba(0, 0, 0, 0);
  --color-btn-border: #3f51b5;
  --color-btn-shadow: #3f51b5;
  --color-btn-inset-shadow: #3f51b5;
  --mid-orange: #ff9800;
  --light-red: #f44336;
}
