.dashboard-filter-bar .dropdown {
  float: right;
}

.dashboard-filter-bar .dropdown-toggle,
.dashboard-filter-bar .dropdown-toggle:hover,
.dashboard-filter-bar .dropdown-toggle:active {
  color: var(--color-btn-text) !important;
  background-color: var(--color-btn-bg) !important;
  box-shadow: var(--color-btn-shadow), var(--color-btn-inset-shadow) !important;
  transition: 0.2s cubic-bezier(0.3, 0, 0.5, 1) !important;
  transition-property: color, background-color, border-color !important;
  border-radius: 0px !important;
  border: 2px solid var(--color-btn-border) !important;
  cursor: pointer !important;
  font-size: 15px !important;
  font-weight: 600 !important;
  width: 300px !important;
}

.dashboard-filter-bar .drop-down-menu {
  width: 300px !important;
  border: 2px solid var(--color-btn-border) !important;
  border-radius: 0px !important;
}

.maintenance-list-top-button {
  color: var(--color-btn-text);
  background-color: var(--color-btn-bg);
  box-shadow: var(--color-btn-shadow), var(--color-btn-inset-shadow);
  transition: 0.2s cubic-bezier(0.3, 0, 0.5, 1);
  transition-property: color, background-color, border-color;
  border-radius: 5px;
  padding: 5px 10px;
  border: 2px solid var(--color-btn-border);
  cursor: pointer;
  margin: 10px;
  font-size: 15px;
  font-weight: 600;
  text-decoration: none;
  width: 150px;
  text-align: center;
}

.maintenance-list-top-button-active {
  color: white;
  background-color: var(--color-btn-text);
}

.dashboard-maintenance {
  background-color: #fff;
  color: #777;
  padding: 15px;
}

.card-maintenance {
  position: relative;
  display: block;
  margin-bottom: 0.75rem;
  background-color: #fff;
  border-radius: 0.25rem;
  box-shadow: 1px 1px 6px var(--light-blue-1);
  border: 0 solid transparent;
}

.side-box {
  position: absolute;
  right: 0;
  top: 0;
  height: 50px;
  width: 60px;
  font-size: 26px;
  border-radius: 0 0 0 100px;
  text-align: center;
}

.side-box i {
  position: relative;
  top: 10px;
  left: 5px;
}

.text-delayed-color {
  color: #dc3545;
}

.text-ongoing-color {
  color: #17a2b8;
}

.text-completed-color {
  color: #28a745;
}

.text-users-color {
  color: #ffc107;
}
