.form-outer-div {
  position: relative;
}

.form-outer-div .input-field {
  width: 100%;
}

.form-outer-div .eye-icon-password {
  position: absolute;
  top: 9px;
  right: 13px;
  cursor: pointer;
}
