.date-picker-relative-div {
  position: relative;
}
.date-picker-input {
  position: absolute;
}
.date-picker-div {
  position: absolute;
  top: 60px;
  z-index: 1;
  border: 1px solid blue;
  background-color: white;
}
.date-picker-submit {
  margin: 5px 20px !important;
  float: right;
}

.input-box-style {
  width: 100%;
  -webkit-align-items: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  background-color: hsl(0, 0%, 100%);
  border-color: hsl(0, 0%, 80%);
  border-radius: 4px;
  border-style: solid;
  border-width: 1px;
  cursor: default;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-flex-wrap: wrap;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  justify-content: space-between;
  min-height: 38px;
  outline: 0 !important;
  position: relative;
  -webkit-transition: all 100ms;
  transition: all 100ms;
  box-sizing: border-box;
}
.date-range-input-box {
  cursor: pointer;
}
