.table_outer_div .profile-img-td {
  width: 250px;
  text-align: center;
}

.table_outer_div .profile-img-td img {
  margin: 30px 0;
  width: 150px;
}

.table_user_view {
  width: 70%;
}
