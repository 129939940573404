.chart-container {
  margin: 10px auto;
  .title {
    margin-bottom: 10px;
  }
}

.pie-chart-container {
  position: relative;
  height: 250px;
  width: 350px;
  align-items: center;
  display: flex;
  justify-content: center;
}

.pie-chart-outer {
  position: absolute;
  top: 0;
  left: 0;
}

.pie-chart-inner {
  margin: 0 5px;
  padding: 0;
  color: rgb(43, 33, 33);
}

.recharts-wrapper {
  margin: auto;
}
