.category-card {
  position: relative;
  display: block;
  margin-bottom: 0.75rem;
  background-color: #fff;
  border-radius: 0.25rem;
  box-shadow: 1px 1px 6px var(--light-blue-1);
  border: 0 solid transparent;
  padding: 15px;
  text-decoration: none !important;
  color: rgb(77, 75, 75);
}

.category-card:hover {
  background-color: var(--light-blue-1);
  color: rgb(77, 75, 75);
}

.badge-section {
  margin: 0 5px 0 10px;
}

.category-card p {
  margin-bottom: 0px;
}
