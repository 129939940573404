.login-background-img {
  width: 100%;
}

.login-img-background-div {
  padding: 0;
  /* min-height: calc(100vh - 60px); */
  min-height: calc(100vh);
  display: flex;
  flex-direction: column;
  background-color: #e6e6e6;

  background-image: url('./login-background.jpg');
  background-position: bottom;
}

.container-width-set-none {
  max-width: inherit !important;
}

.button-for-webpage:link,
.button-for-webpage:visited {
  background-color: var(--dark-blue);
  color: rgb(255, 255, 255);
  border: 2px solid var(--dark-blue);
  padding: 10px 20px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
}

.button-for-webpage:hover,
.button-for-webpage:active {
  background-color: var(--light-blue);
  color: white;
}

.margin-top-set-5vh {
  margin-top: 5vh;
}

.margin-top-set-20vh {
  margin-top: 20vh;
}

.margin-top-set-10vh {
  margin-top: 10vh;
}

.login {
  height: 100vh !important;
}

.logo-login {
  width: 150px;
  border-radius: 10px;
}
